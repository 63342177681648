
.column-left {
    padding: 20px;
    float: left;
    width: 33%;
}

.column-right {
    padding: 20px;
}

.row-height {
  height: 100vh;
}

.column-header {
    height: 5vh;
}
.column-scroll {
  height: 87vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 2em;
}

.submit-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
}


.container-fluid {
}

.row-height {
    height: 100vh;
}

.submit-button {
    margin-top: 10px;
}

.copy-button {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.card-result {
    width: 18rem;
    margin-bottom: 10px;
    max-height: 275px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-logo {
    float: right;
    width: 4em;
}

.card-link-footer {
    margin: unset;
}

.left-aligned {
    text-align: left;
}

.download-csv {
    position: fixed;
    right: 25px;
    top: 15px;
}


.dslc-button {
    text-align: center;
    margin-bottom: 50px;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: top left;
    cursor: pointer;
}

.dslc-button a {
    background-color: #f37648;
    border-radius: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 33px;
    padding-right: 33px;
    display: inline-block;
    color: #ffffff;
    font-size: 21px;
    font-weight: 400;
}

.dslc-button a {
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    box-shadow: none;
    -webkit-appearance: none;
}
